import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { createUser, getAllUsers, getAllVM, updateVM, publish_vm_status_req } from "../../api/routes";
import io from 'socket.io-client';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { json } from 'react-router-dom';
import  mqtt from "mqtt";
import Checkbox from '@mui/material/Checkbox';
const options = {
    username: 'pi', // Optional: if your broker requires authentication
    password: 'raspberry', // Optional: if your broker requires authentication
    rejectUnauthorized: false  // Only set to false if using a self-signed certificate
};

// Connect to the broker using wss://
const client_left = mqtt.connect('wss://ivm9.in:9001');
//const client_left = mqtt.connect('wss://195.35.21.196:8001');
    client_left.on('connect', () => {
        console.log('Connected to MQTT broker left');
    });

    client_left.on('error', (error) => {
        console.error('MQTT error:', error);
    });

    client_left.on('close', () => {
        console.log('MQTT connection closed');
    });
    client_left.on("error", (err) => {
        console.error("Connection error:", err);
      });
export function LeftGrid({user_id}) {
    // const ws = new WebSocket("ws://195.35.21.196:8001/ws");
    // ws.onopen = () => ws.send("++++++++++++++++++++++++++++++++++ Connected");
    // // console.log(">>>>>>>>>>>>>>>>>>>.",{ sendMessage, lastMessage, readyState });
    // ws.onmessage= (event) => console.log(">>>>>>>>>>>.",JSON.parse(event.data));
    
    
    // TODO: Remove these comments
    // setInterval(() => {
    //     console.log('Send req for vending machine status');
    //     opVm.forEach(async (item) => {
    //         // await publish_vm_status_req(item.guid)
    //         client_left_left.publish(`vm/${item.guid}/pi/status`, "vm-status-request");
    //         // console.log("published for vm-status-request")
    //     });
    // }, 10000);

    

    const [loading, setLoading] = useState(true);
    const [opVm, setOpVM] = useState([]);
    const [curr_online, setCurrentOnline] = useState([]);
    const [curr_waiting, setCurrentWaiting] = useState([]);
    const [light, setLight] = useState(0);
    const [alarm, setAlarm] = useState(0);
    const [f1, setF1] = useState(0);
    const [f2, setF2] = useState(0);
    const [selectedVM, setSelectedVM] = useState('');
    // const { sendMessage, lastMessage, readyState } = useWebSocket('ws://195.35.21.196:8000/ws');


    const getOperatorVMs = (vms) => {
        const res = [];
        vms.forEach(vm => {
            if(vm.operator_assigned_id === user_id) {
                res.push(vm);
                client_left.subscribe(`vm/${vm.guid}/call/status`)
                client_left.subscribe(`heartbeat/vendingmachine/alive/${vm.guid}`)
                client_left.subscribe(`heartbeat/vendingmachine/dead/${vm.guid}`)
            }
        });
        return res;
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            // const op_data = await getAllUsers();
            await getAllVM().then(vm => {
                // console.log({vms: vm});
                // const [vm_details, op_details, vms_for_dropdown_data] = getRequiredVmDetails(vm, op_data);
                setOpVM(getOperatorVMs(vm));
                // console.log("fetchData", {opVm});
                // setVMDetails(vm_details);
                // setOpData(removeDuplicateObjects(op_details));
                setLoading(false);
            });
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    }
    
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        client_left.on('message', (topic, message) => {
            message = JSON.parse(message.toString())
            console.log('++++++++++++++++++ MQTT message received', topic, message, message["type"]);
            // update call status .....
            if (message["type"] === "call_status" || message["type"] === "status") {
                fetchData();
            }
        });
    },[])

    const lightON = () => {
        // console.log({ selectedVM });
        if (!selectedVM) {
            // eslint-disable-next-line no-restricted-globals
            confirm(`Please select a vending machine for the action`);
            return;
        }
        setLight(!light);
        console.log("published light",`vm/pi/${selectedVM.guid}/light`, `${light? "off" : "on"}`);
        var topic = `vm/pi/${selectedVM.guid}/light`;
        var message = `${light? "off" : "on"}`;
        if (client_left.connected) {
            client_left.publish(topic, message, (err) => {
                if (err) {
                    console.error('Publish error:', err);
                } else {
                    console.log(`Message published to ${topic}: ${message}`);
                }
            });
        } else {
            console.warn('MQTT client is not connected');
        }
        // client_left.publish(`vm/pi/${selectedVM.guid}/light`, `${light? "off" : "on"}`);
    }

    const onAlarm = () => {
        if (!selectedVM) {
            // eslint-disable-next-line no-restricted-globals
            confirm(`Please select a vending machine for the action`);
            return;
        }
        setAlarm(!alarm);
        client_left.publish(`vm/pi/${selectedVM.guid}/alarm`, `${alarm? "off" : "on"}`);
    }

    const onF1 = () => {
        // console.log({ selectedVM });
        if (!selectedVM) {
            // eslint-disable-next-line no-restricted-globals
            confirm(`Please select a vending machine for the action`);
            return;
        }
        setF1(!f1);
        client_left.publish(`vm/pi/${selectedVM.guid}/f1`, `${f1? "off" : "on"}`);
    }

    const onF2 = () => {
        if (!selectedVM) {
            // eslint-disable-next-line no-restricted-globals
            confirm(`Please select a vending machine for the action`);
            return;
        }
        setF2(!f2);
        client_left.publish(`vm/pi/${selectedVM.guid}/f2`, `${f2? "off" : "on"}`);
    }

    const updateCallStatus = (id, status) => {
        client_left.publish(`vm/${id}/call/status`, `{\"type\":\"call_status\",\"value\":\"${status}\",\"vm_id\":\"${id}\"}`);
    }

    return (
    <Box>
        <Grid container style={{ backgroundColor: "#eeeeee" }} pl={2}>
            <Grid item xs={6} >
            <Typography variant="h6" >Current Online: {opVm.map((value) => { if(value.status === 10) return value.name }).join(" > ")}</Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography variant="h6" >Waiting: {opVm.map((value) => { if(value.call_status === 20) return value.name }).join(" > ")}</Typography>
            </Grid>
        </Grid>
        <Grid container justifyContent="center" mt={1} spacing={2} sx={{p:1}}>
            {opVm.map((value) => (
            <Grid key={value} item sx={{mx: 'auto', textAlign: 'center',}}>
                <Paper
                sx={{
                    height: 200,
                    width: 120,
                    backgroundColor: '#707373',
                    mx: 'auto',
                    textAlign: 'center',
                    color: "#ffffff"
                }}
                >
                    <Typography py={1} variant="h6" >{value.name}</Typography>
                    <Button fullWidth={true} onClick={() => updateCallStatus(value.guid, 5)} variant="contained" sx={{ mt: .5, mx: .5 }} size="small" color="success" style={value.call_status==10?{color: "orange"} : value.call_status == 5 ? {color:"black",}: {color:"brown"}} disabled={value.status !== 10}>{value.status === 10? "ONLINE" : "OFFLINE"}</Button>
                    <Button fullWidth={true} variant="contained" size="small" color="error" sx={{ mt: .5, mx:.5 }} onClick={() => updateCallStatus(value.guid, 20)}>WAIT</Button>
                    <Button fullWidth={true} variant='contained' size="small" color='warning' sx={{ mt: .5, mx:.5 }} onClick={() => updateCallStatus(value.guid, 0)}>End Call</Button>
                    <Checkbox  color="success" sx={{color: "white"}} onChange={(event) => event.target.checked ? setSelectedVM(value) : setSelectedVM("")} />
                </Paper>
            </Grid>
            ))}
        </Grid>
        <Grid container justifyContent="center" sx={{p:2, mt:3}} mt={1} spacing={2} style={{ backgroundColor: "#eeeeee" }}>
            <Grid container pl={2}>
                <Typography variant="h6" >Machine Options</Typography>
            </Grid>
            {/* <Grid container justifyContent="center">
                <Grid p={1}><Button variant="contained" color="secondary" >Edit Machine</Button></Grid>
                <Grid p={1}><Button variant="contained" color="secondary">Add/Remove</Button></Grid>
                <Grid p={1}><Button variant="contained" color="secondary">Inventory</Button></Grid>
                <Grid p={1}><Button variant="contained" color="secondary">Sales</Button></Grid>
            </Grid> */}
            <Grid container justifyContent="center">
                <Grid p={1}><Button variant="contained" color="success" onClick={lightON}>{!light? "Lights On" : "Lights Off"}</Button></Grid>
                <Grid p={1}><Button variant="contained" color="success" onClick={onAlarm}>{!alarm? "Switch On Alarm" : "Switch Off Alarm"}</Button></Grid>
                <Grid p={1}><Button variant="contained" color="success" onClick={onF1}>{!f1? "Enable F1" : "Disable F1" }</Button></Grid>
                <Grid p={1}><Button variant="contained" color="success" onClick={onF2}>{!f2? "Enable F2" : "Disable F2" }</Button></Grid>
            </Grid>
        </Grid>
    </Box>
    )

    // for (let i = 0; i < 4; i++) {
    //     <Container maxWidth="sm">
            {/* { new Array(4).array.forEach(element => { */}
                
            {/* }) */}
            {/* } */}
    //     </Container>
    // }

}