import { RightGrid } from "./RightGrid";
import React, {useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import  mqtt from "mqtt";
import { useParams } from 'react-router-dom';

export default function LocalMachine() {
    const { vm_id } = useParams();

    const options = {
      username: 'pi', // Optional: if your broker requires authentication
      password: 'raspberry', // Optional: if your broker requires authentication
      rejectUnauthorized: false  // Only set to false if using a self-signed certificate
  };
  
  // Connect to the broker using wss://
  const client = mqtt.connect('wss://ivm9.in:9001');

    const [tableData, setTableData] = useState([]);

    const price_total = tableData.reduce((accumulator ,item) => {
      return accumulator += item.price;
    }, 0)
    client.subscribe(`vm/pi/local/${vm_id}`);
    client.on('message', (topic, data) => {
        const LocalMachineData = JSON.parse(data)
        console.log("+++++++++++++++++++++++",{topic, LocalMachineData});
        setTableData(LocalMachineData);
    });
    client.on("error", (err) => {
      console.log("Connection error:", err);
    });

    
    return (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="center">Unit Price</TableCell>
                <TableCell align="center">Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row) => (
                <TableRow>
                    <TableCell align="center">
                    {row.desc}
                    </TableCell>
                    <TableCell align="center" >
                    {row.qty}
                    </TableCell>
                    <TableCell align="center">{row.unit}</TableCell>
                    <TableCell align="center">{row.price}</TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="center" sx={{fontWeight:"bold", fontSize:"20px", border:2}}>Total: Rs.{price_total}</TableCell></TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
}
