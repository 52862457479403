
import { 
    Table,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    TableContainer,
    Paper,
    IconButton,
} from '@mui/material/';
import DeleteIcon from '@mui/icons-material/Delete';
import  mqtt from "mqtt";
import { useEffect, useState } from 'react';

const options = {
    username: 'pi', // Optional: if your broker requires authentication
    password: 'raspberry', // Optional: if your broker requires authentication
    rejectUnauthorized: false  // Only set to false if using a self-signed certificate
};

// Connect to the broker using wss://
const client = mqtt.connect('wss://ivm9.in:9001');
export default function RightGridTable({rows, setQuantity, deleteRow, TAX_RATE, invoiceSubtotal, invoiceTaxes, invoiceTotal, selectedItems, itemSlots}) {
    
    // const [row, setRow] = useState(rows)
    // const [loading, setLoading] = useState(true)

    let on_call_vm = '';
        selectedItems.length > 0 && selectedItems.forEach((item_id, index) => {
            itemSlots.forEach((item) => {
                if (item.item_id_id === item_id) {
                    // console.log("++++++VM-MATCHED+++++++",item)
                    on_call_vm = item.vending_machine_id_id;
                }
            });
        })
    client.publish(`vm/pi/local/${on_call_vm}`, JSON.stringify(rows));
    // client.subscribe(`vm/pi/local`);
    // client.on('message', (topic, data) => {
    //     console.log({topic, data: JSON.parse(data)});
    // });
    useEffect(() => {

        console.log("RIGHT TABLE");
        // setLoading(true)
        // setRow(rows)
        // setLoading(false)
        // fetchData();
        // console.log("+++++++++++++++",{itemData});
    }, []);

    function ccyFormat(num) {
        return `${num.toFixed(2)}`;
    }

    const deleteTableRow = (e, index) => {
        deleteRow(e, index)
        // useEffect()
        // console.log("rrrrrrroooooooowwwwwwwwwwwws",{rows}, index);
        // let curr_rows = [...row];
        // console.log("111111111", {curr_rows})
        // curr_rows = curr_rows.filter((item, i) => i !== index);
        // console.log("222222222", {curr_rows})
        // setRow(curr_rows);
    }


    return <TableContainer component={Paper} sx={{backgroundColor:""}}>
        <Table aria-label="spanning table">
            <TableHead >
                <TableRow>
                    <TableCell  className='table-cell' align="center" colSpan={3}>
                    Details
                    </TableCell>
                    <TableCell className='table-cell' align="center">Price</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className='table-cell' align="left">Desc</TableCell>
                    <TableCell className='table-cell' align="left">Stock</TableCell>
                    <TableCell className='table-cell' align="left">Qty.</TableCell>
                    <TableCell className='table-cell' align="left">Unit</TableCell>
                    <TableCell className='table-cell' align="left">Sum</TableCell>
                    <TableCell className='table-cell' align="left">Delete Row</TableCell>
                </TableRow>
            </TableHead>
            <TableBody style={{color:"black"}}>
                {rows.map((row, index) => (
                    <TableRow key={row.desc}>
                        <TableCell className='table-cell' align="left">{row.desc}</TableCell>
                        <TableCell className='table-cell' align="left">{row.stock}</TableCell>
                        <TableCell className='table-cell' align="left"><input type="number" value={rows[index].qty} style={{ border: 'none', width:"25%"}} onFocus={(e) => e.target.style.outline = 'none'} onChange={(e) => setQuantity(e, index)} /></TableCell>
                        <TableCell className='table-cell' align="left">{row.unit}</TableCell>
                        <TableCell className='table-cell' align="left">{ccyFormat(row.price)}</TableCell>
                        <TableCell className='table-cell' align="left"><IconButton aria-label="delete" color="error" onClick={(e) => deleteTableRow(e, index)}><DeleteIcon /></IconButton></TableCell>
                    </TableRow>
                ))}
                <TableRow>
                    <TableCell className='table-cell' rowSpan={3} />
                    <TableCell className='table-cell' colSpan={2}>Subtotal</TableCell>
                    <TableCell className='table-cell' align="left">{ccyFormat(invoiceSubtotal)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className='table-cell'>Tax</TableCell>
                    <TableCell className='table-cell' align="left">{`${(TAX_RATE * 100).toFixed(0)} %`}</TableCell>
                    <TableCell className='table-cell' align="left">{ccyFormat(invoiceTaxes)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className='table-cell' colSpan={2}>Total</TableCell>
                    <TableCell className='table-cell' align="left">{ccyFormat(invoiceTotal)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TableContainer>
}
