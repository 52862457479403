// import Sidenav from '../../utils/AdminSidebar';
// import GroupIcon from '@mui/icons-material/Group';
// import MicrowaveIcon from '@mui/icons-material/Microwave';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
// import DangerousIcon from '@mui/icons-material/Dangerous';
// import LogoutIcon from '@mui/icons-material/Logout';
// import Logout from '../../utils/Logout';
// import AdminPanel from '../admin/AdminPanel';
import { OperatorPannel } from "./OperatorPanel";
export default function Operator({user_id}) {
    return <OperatorPannel user_id={user_id} />
}