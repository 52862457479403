import { Routes, Route, useLocation  } from "react-router-dom";
import Home from "./client/home";
import SignIn from "./client/login";
import LocalMachine from "./client/operator/LocalMachine"

export default function App() {
  const state = useLocation();
  return (
        <Routes>
          <Route exact path="/" element={<SignIn/>} />
          <Route path="/home" element={<Home role={state && state.state && state.state.role} />} />
          <Route exact path="/local/:vm_id" element={<LocalMachine role={state && state.state && state.state.role}/>} />
          {/* <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} /> */} 
          {/* <Route path="*" element={<NoPage />} /> */}
        </Routes>
  );
}
