import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { createItem, getAllItems, updateItem, deleteItem } from '../../api/routes'
// import { data, states } from './makeData';

const VendingMachineItems = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [itemData, setItemData] = useState(() => []);
  const [validationErrors, setValidationErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const updateItemResponseData = (data) =>{
    data.forEach((item, index) => {
      data[index]["created_at"] = (new Date(item.created_at)).toLocaleString();
      data[index]["updated_at"] = (new Date(item.updated_at)).toLocaleString();
      data[index]["metadata"] = item.metadata?.["description"]?item.metadata.description:"";
    })
    console.log("lllllllllllll",itemData);
    return data;
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const items = await getAllItems();

      setItemData(updateItemResponseData(items));
      // const response = await getAllVM();
      // console.log({vms: response});
      // setTableData(updateVMResponseData(response, users));
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // console.log({admins});
  }, []);

  const handleCreateNewRow = async (values) => {

    // }
    // tableData.push(values);
    // setTableData([...tableData]);
    delete values["guid"];
    delete values["created_at"];
    delete values["updated_at"];
    values["item_price"] = parseInt(values["item_price"], 10);
    values["tax"] = parseFloat(values["tax"]);
    values["metadata"] = {"description": values["metadata"]};
    await createItem(values);
    await fetchData();
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      itemData[row.index] = values;
      //send/receive api updates here, then refetch or update local table data for re-render
      console.log("handleSaveRowEdits -----", values);
      setItemData([...itemData]);
      await updateItem(values.guid, {
        "item_name": values.item_name,
        "item_price": values.item_price,
        "tax": values.tax,
        "metadata": {"description": values.metadata},
      });
      fetchData();
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    async (row) => {
      if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(`Are you sure you want to delete ${row.getValue('item_name')}`)
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      await deleteItem(row["original"]["guid"])
      itemData.splice(row.index, 1);
      setItemData([...itemData]);
    },
    [itemData],
  );

  // const getCommonEditTextFieldProps = useCallback(
  //   (cell) => {
  //     return {
  //       error: !!validationErrors[cell.id],
  //       helperText: validationErrors[cell.id],
  //       onBlur: (event) => {
  //         const isValid =
  //           cell.column.id === 'email'
  //             ? validateEmail(event.target.value)
  //             : cell.column.id === 'age'
  //             ? validateAge(+event.target.value)
  //             : validateRequired(event.target.value);
  //         if (!isValid) {
  //           //set validation error for cell if invalid
  //           setValidationErrors({
  //             ...validationErrors,
  //             [cell.id]: `${cell.column.columnDef.header} is required`,
  //           });
  //         } else {
  //           //remove validation error for cell if valid
  //           delete validationErrors[cell.id];
  //           setValidationErrors({
  //             ...validationErrors,
  //           });
  //         }
  //       },
  //     };
  //   },
  //   [validationErrors],
  // );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'guid',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
      },
      {
        accessorKey: 'item_name',
        header: 'Item Name',
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          // ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'item_price',
        header: 'Price',
        size: 80,
        muiEditTextFieldProps: {
          // ...getCommonEditTextFieldProps(cell),
          type: 'number',
          required: true,
          error: !!validationErrors?.item_price,
          helperText: validationErrors?.item_price,
          //remove any previous validation errors when user focuses on the input
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              item_price: undefined,
            }),
        },
      },
      {
        accessorKey: 'tax',
        header: 'Tax',
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          // ...getCommonEditTextFieldProps(cell),
          type: 'number',
        }),
      },
      {
        accessorKey: 'metadata',
        header: 'Item Description',
        size: 140,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          // ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'created_at',
        header: 'Created At',
        size: 40,
        enableColumnOrdering: false,
        enableEditing: false, 
        enableSorting: false,
      },
      {
        accessorKey: 'updated_at',
        header: 'Updated At',
        size: 40,
        enableColumnOrdering: false,
        enableEditing: false, 
        enableSorting: false,
      },
    ],
    // [getCommonEditTextFieldProps],
  );

  return (
    <>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={itemData}
        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="secondary"
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
          >
            Create New Item
          </Button>
        )}
      />
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
    </>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {}),
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign="center">Create New Account</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) => (
              (column.accessorKey === "item_name" || column.accessorKey === "metadata" || column.accessorKey === "item_price" || column.accessorKey === "tax") && <TextField
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                onChange={(e) =>
                  setValues({ ...values, [e.target.name]: e.target.value })
                }
              />
            ))}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
          Create New Item
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// const validateRequired = (value) => !!value.length;
// const validatePrice = (price) =>price
//     .match(
//       /^[0-9]/,
//     );

// function validateItem(item) {
//   return {
//     price: validatePrice(item.item_price)
//   };
// }

export default VendingMachineItems;
