import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
// import { data, states } from './makeData';
import {getAllUsers, updateUser, createUser, deleteUser} from '../../api/routes'
import { TableRowsLoader } from '../../utils/TableRowsLoader';
import { create } from '@mui/material/styles/createTransitions';

const Admin = () => {
    console.log("Inside Admin--------");
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [loading, setLoading] = useState(false)

    const updateVMResponseData = (data) =>{
    data.map((item, index) => {
        // data[index]["status"] = item.status === 10 ? 'Online' : (item.status === 20? "Offline" :(item.status === 0? "New":"Not Working"));
        data[index]["created_at"] = (new Date(item.created_at)).toLocaleString();
        data[index]["updated_at"] = (new Date(item.updated_at)).toLocaleString();
    })
    return data.filter(item => item.is_admin)
    }

    const fetchData = async () => {
    setLoading(true);
    try {
        const response = await getAllUsers();
        console.log("########", {response});

        setTableData(updateVMResponseData(response));
        setLoading(false);
    } catch (error) {
        console.log("error", error);
        setLoading(false);
    }
    }

    useEffect(() => {
    fetchData();
    // console.log({loading, tableData});
    }, [])

    // console.log("initial",{tableData});
    const handleCreateNewRow = async (values) => {
    // values["items_dispensed"] = 0;
    // values["status"] = "New";
    delete values["updated_at"];
    delete values["created_at"];
    delete values["guid"]
    values["is_active"] = true;
    values["is_operator"] = false;
    values["is_superuser"] = false;
    values["is_admin"] = true;
    // values["mobile"] = parseInt(values["mobile"], 10);
    const data = await createUser(values);
    tableData.push(data);
    await fetchData();
    };

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
        tableData[row.index] = values;
        //send/receive api updates here, then refetch or update local table data for re-


        delete values["updated_at"];
        delete values["created_at"];
        console.log("edit data----", values);

        values["password"] = "";
        const id = values.guid;
        delete values["guid"];
        setTableData([...tableData]);
        await updateUser(id , values);
        exitEditingMode(); //required to exit editing mode and close modal
    }
    };

    const handleCancelRowEdits = () => {
    setValidationErrors({});
    };

    const handleDeleteRow = useCallback(
    async (row) => {
        if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(`Are you sure you want to delete ?`)
        ) {
        return;
        }
        //send api delete request here, then refetch or update local table data for re-render
        tableData.splice(row.index, 1);
        await deleteUser(row["original"]["guid"]);
        await fetchData();
    },
    [tableData],
    );

    const getCommonEditTextFieldProps = useCallback(
    (cell) => {
        return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
            const isValid =
                cell.column.id === 'email'
                ? validateEmail(event.target.value)
                // : cell.column.id === 'age'
                // ? validateAge(+event.target.value)
                : validateRequired(event.target.value);
            if (!isValid) {
                //set validation error for cell if invalid
                setValidationErrors({
                ...validationErrors,
                [cell.id]: `${cell.column.columnDef.header} is required`,
                });
            } else {
                //remove validation error for cell if valid
                delete validationErrors[cell.id];
                setValidationErrors({
                ...validationErrors,
                });
            }
        },
        };
    },
    [validationErrors],
    );

    const columns = useMemo(
    () => [
        {
        accessorKey: 'guid',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 200,
        },
        {
            accessorKey: 'first_name',
            header: 'First Name',
            size: 40,
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
                type: '',
            }),
        },
        {
            accessorKey: 'last_name',
            header: 'Last Name',
            size:30,
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
                type: '',
            }),
        },
        {
            accessorKey: 'emp_id',
            header: 'Employee Id',
            size: 30,
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
            }),
        },
        {
            accessorKey: 'email',
            header: 'Email',
            size: 40,
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
                type: 'string',
            }),
        },
        {
            accessorKey: 'mobile',
            header: 'Mobile',
            size: 30,
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
                type: 'string',
            }),
        },
        {
            accessorKey: 'company_name',
            header: 'Company Name',
            size: 30,
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
                type: 'string',
            }),
            },
        {
            accessorKey: 'address',
            header: 'Address',
            size: 30,
            muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                ...getCommonEditTextFieldProps(cell),
                type: 'string',
            }),
            },
        {
            accessorKey: 'created_at',
            header: 'Created At',
            size: 40,
            enableColumnOrdering: false,
            enableEditing: false, 
            enableSorting: false,
        },
        {
            accessorKey: 'updated_at',
            header: 'Updated At',
            size: 40,
            enableColumnOrdering: false,
            enableEditing: false, 
            enableSorting: false,
        },
    //   {
    //     accessorKey: 'state',
    //     header: 'State',
    //     muiTableBodyCellEditTextFieldProps: {
    //       select: true, //change to select for a dropdown
    //       children: states.map((state) => (
    //         <MenuItem key={state} value={state}>
    //           {state}
    //         </MenuItem>
    //       )),
    //     },
    //   },
    ],
    [getCommonEditTextFieldProps],
    );

    return (
    <>
        {loading? <TableRowsLoader /> : <MaterialReactTable
        initialState={{ columnVisibility: { guid: false } }}
        displayColumnDefOptions={{
            'mrt-row-actions': {
            muiTableHeadCellProps: {
                align: 'center',
            },
            size: 120,
            },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        enableColumnOrdering
        enableColumnActions={false}
        enableEditing
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActions={({ row, table }) => ({columnVisibility: { guid: false }} &&
            <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
                <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
                </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
                <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
                </IconButton>
            </Tooltip>
            </Box>
        )}

        renderTopToolbarCustomActions={() => (
            <Button
            color="secondary"
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
            >
            Create New Admin
            </Button>
        )}
        />}
        <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
        />
    </>
    );
};

    //example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
    const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
        acc[column.accessorKey ?? ''] = '';
        return acc;
    }, {}),
    );

    const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
    };

    return (
    <Dialog open={open}>
        <DialogTitle textAlign="center">Create New Admin</DialogTitle>
        <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
            <Stack
            sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
                my:3,
            }}
            >
            {columns.map((column) => (
                (column.accessorKey !== 'created_at' && column.accessorKey !== 'updated_at' && column.accessorKey !== "guid") && 
                <TextField
                    key={column.accessorKey}
                    label={column.header}
                    name={column.accessorKey}
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
            ))}
            <TextField
                    key="password"
                    label="Password"
                    name="password"
                    type="password"
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
            />
            </Stack>
        </form>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="secondary" onClick={handleSubmit} variant="contained">
            Create New Account
        </Button>
        </DialogActions>
    </Dialog>
);
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
    .toLowerCase()
    .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
// const validateAge = (age) => age >= 18 && age <= 50;

export default Admin;
