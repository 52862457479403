import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";

export default function Logout({color="error"}) {
    const navigate = useNavigate();
    const logout = () => {
        localStorage.removeItem("authenticated");
        navigate("/");
    }
    return <Button color={color} variant={color === "secondary"?"contained":""} onClick={logout}>Logout</Button>
}