import { Box,
        Typography,
        Paper,
        Grid, 
        Divider, 
        TableContainer, 
        Table, 
        TableBody, 
        TableHead,
        TableRow,
        TableCell,
        Button,
        Stack,
        IconButton,
        Dialog,
        DialogActions,
        DialogContent,
        DialogTitle,
        Tooltip,
        TextField,
        MenuItem,
        InputLabel,
        Select,
        Chip } from "@mui/material" 
import { Delete, Edit } from '@mui/icons-material';
import { DataGrid  } from '@mui/x-data-grid';
import { MaterialReactTable } from 'material-react-table';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { createUser, getAllUsers, getAllVM, updateVM, deleteUser } from "../../api/routes";
import { removeDuplicateObjects } from "../../utils/functions"

const columns = [
    { field: 'id', headerName: 'ID', width: 150 },
    {
        field: 'name',
        headerName: 'OP Name',
        width: 150,
    },
    {
        field: 'emp_id',
        headerName: 'Employee Id',
        width: 90,
    },
    {
        field: 'vm',
        headerName: 'Total Vending Machine',
        width: 180,
    },
    {
        field: 'online_vm',
        headerName: 'Online Vending Machine',
        width: 180,
    },
    // { field: 'status', headerName: 'Status', width: 100, type: 'number' },
    { field: 'sales_amount', headerName: 'Sales Amount', width: 100, },
    { field: 'items_dispensed', headerName: 'Items Dispensed', width: 130 },
];


function createData(
    id,
    status,
    sales,
    units_sold,
) {
    return { id, status, sales, units_sold };
}

export default function AdminPanel({user_id}){
    console.log({user_id});
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [opData, setOpData] = useState(() => []);
    const [validationErrors, setValidationErrors] = useState({});
    const [vm_details, setVMDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [vm, setVM] = useState([]);
    const [vms_for_dropdown, setVMForDropDown] = useState([]);
    const [rows, setRows] = useState([]);

    const getRequiredVmDetails = (data, op_data) => {
        const result = [];
        const result_obj = {};
        const op_res = [];
        const vms_for_dropdown_data = [];
        data.forEach(element => {
            if (element.admin_assigned_id === user_id) {
                vms_for_dropdown_data.push(element);
                if (element.operator_assigned_id in result_obj) {
                    result_obj[element.operator_assigned_id]["vm"] += 1;
                    result_obj[element.operator_assigned_id]["online_vm"] = element.status === 10? result_obj[element.operator_assigned_id]["online_vm"]+1 : result_obj[element.operator_assigned_id]["online_vm"];
                    result_obj[element.operator_assigned_id]["sales_amount"] += element.sales_amount;
                    result_obj[element.operator_assigned_id]["items_dispensed"] += element.items_dispensed;
                } else if(element.operator_assigned_id){
                    result_obj[element.operator_assigned_id] = {};
                    result_obj[element.operator_assigned_id]["id"] = element.operator_assigned_id;
                    result_obj[element.operator_assigned_id]["name"] = "";
                    result_obj[element.operator_assigned_id]["emp_id"] = "";
                    result_obj[element.operator_assigned_id]["vm"] = 1;
                    result_obj[element.operator_assigned_id]["online_vm"] = element.status === 10? 1 : 0;
                    result_obj[element.operator_assigned_id]["sales_amount"] = element.sales_amount;
                    result_obj[element.operator_assigned_id]["items_dispensed"] = element.items_dispensed;
                }
                
                op_data.forEach((item, index) => {
                    if (item.guid === element.operator_assigned_id) {
                        if (op_data[index]["vm"]) {
                            op_data[index]["vm"].push(element.name);
                            op_data[index]["vm_ids"].push(element.guid);
                        } else {
                            op_data[index]["vm"] = [element.name];
                            op_data[index]["vm_ids"] = [element.guid];
                        }
                        op_res.push(item);
                        result_obj[element.operator_assigned_id]["name"] = item.first_name + " " +item.last_name;
                        result_obj[element.operator_assigned_id]["emp_id"] = item.emp_id;
                        console.log(")))))))))))))", item.guid, element.operator_assigned_id)
                    }
                });
            }
        });
        Object.keys(result_obj).forEach((key) => {
            result.push(result_obj[key]);
        })
        return [result, op_res, vms_for_dropdown_data];
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            const op_data = await getAllUsers();
            await getAllVM().then(vm => {
                console.log({vms: vm});
                const [vm_details, op_details, vms_for_dropdown_data] = getRequiredVmDetails(vm, op_data);
                console.log({ vms_for_dropdown_data });
                setVMForDropDown(vms_for_dropdown_data);
                console.log({vm_details});
                setVMDetails(vm_details);
                setOpData(removeDuplicateObjects(op_details));
                setLoading(false);
            });
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    }
    
    useEffect(() => {
    fetchData();
    console.log("----start----");
    console.log({vm_details, opData});
    }, [])

    const handleChange = (event) => {
        console.log("+++handleChange+++", event.target.value);
        setVM(event.target.value);
    };

    const handleCreateNewRow = async (values) => {
        console.log("+++++", values, vm);
        opData.push(values);
        setOpData(removeDuplicateObjects([...opData]));
        delete values["updated_at"];
        delete values["created_at"];
        delete values["guid"];
        delete values["vm"];
        values["is_active"] = true;
        values["is_operator"] = true;
        values["is_superuser"] = false;
        values["is_admin"] = false;
        const data = await createUser(values);
        const promiselist = [];
        vm.forEach(vm_id => {
            promiselist.push(updateVM(vm_id, {"operator_assigned" : data.guid}));
        })
        await Promise.all(promiselist);
        setVM([]);
        fetchData();
    };

    const onRowsSelectionHandler = (ids) => {
        // console.log(">>>>>>>>",{ids}, vms_for_dropdown);
        const selectedRowsData = ids.map((id) => opData.find((row) => row.guid === id));
        
        const selected_vm_ids = selectedRowsData[0].vm_ids;
        const selected_vms = []
        
        vms_for_dropdown.forEach(vm => {
            if (selected_vm_ids.includes(vm.guid)) {
                selected_vms.push(createData(vm.guid, vm.status, vm.sales_amount, vm.items_dispensed));
            }
        });
        console.log(">>>>>>>>",{selected_vms});
        setRows(selected_vms);
    }

    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        console.log(">>>>>>>>EDIT11111111111>>>>>>>>>>>", {row, values});
        if (!Object.keys(validationErrors).length) {
            const vm_id = values["vm"];
            let edited_vm = "";
            
            vms_for_dropdown.forEach(item => {
                if (item.guid === vm_id)
                    edited_vm = item
            });
            values["vm"] = edited_vm["name"];
            opData[row.index] = values;
            //send/receive api updates here, then refetch or update local table data for re-render
            setOpData([...opData]);
            await updateVM(vm_id, {"operator_assigned": values["guid"]});
            exitEditingMode(); //required to exit editing mode and close modal
        }
    };
    
    const handleCancelRowEdits = () => {
    setValidationErrors({});
    };
    
    const handleDeleteRow = useCallback(
    async (row) => {
        console.log(">>>>>>>>>>>>>>>>>>>", {row});
        if (
        // eslint-disable-next-line no-restricted-globals
        !confirm(`Are you sure you want to delete ${row['original']['first_name']}`)
        ) {
        return;
        }
        //send api delete request here, then refetch or update local table data for re-render
        opData.splice(row.index, 1);
        await deleteUser(row['original']['guid'])
        setOpData([...opData]);
        await fetchData();
    },
    [opData],
    );
    
    const getCommonEditTextFieldProps = useCallback(
    (cell) => {
        // console.log("}}}}}}}}",{cell});
        return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
            const isValid =
            cell.column.id === 'email'
                ? validateEmail(event.target.value)
                : validateRequired(event.target.value);
            if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
                ...validationErrors,
                [cell.id]: `${cell.column.columnDef.header} is required`,
            });
            } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
                ...validationErrors,
            });
            }
        },
        };
    },
    [validationErrors],
    );

    const operator_columns = useMemo(
    () => [
        {
            accessorKey: 'guid',
            header: 'ID',
            enableColumnOrdering: false,
            enableEditing: false, //disable editing on this column
            enableSorting: false,
            size: 200,
            },
            {
                accessorKey: 'first_name',
                header: 'First Name',
                size: 40,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: '',
                }),
            },
            {
                accessorKey: 'last_name',
                header: 'Last Name',
                size:30,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: '',
                }),
            },
            {
                accessorKey: 'emp_id',
                header: 'Employee Id',
                size: 30,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'email',
                header: 'Email',
                size: 40,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: 'string',
                }),
            },
            {
                accessorKey: 'mobile',
                header: 'Mobile',
                size: 30,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: 'string',
                }),
            },
            {
                accessorKey: 'company_name',
                header: 'Company Name',
                size: 30,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: 'string',
                }),
                },
            {
                accessorKey: 'address',
                header: 'Address',
                size: 30,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                    type: 'string',
                }),
            },
            {
                accessorKey: 'vm',
                header: 'Vending Machine',
                // enableEditing: false,
                editVariant: 'select',
                muiEditTextFieldProps: {
                    select: true, //change to select for a dropdown
                    onChange: (event) => setVM(event.target.value) ,
                    // defaultValue:cell.getValue(),
                    // displayEmpty: true,
                    // renderValue: cell.getValue(),
                    children: vms_for_dropdown && vms_for_dropdown.map((vm) => (
                            <MenuItem key={vm.guid} value={vm.guid}>
                                {vm.name}
                            </MenuItem>
                            )),
                    error: !!validationErrors?.vms_for_dropdown,
                },
                // editSelectOptions: ["abc", "def"], // vms_for_dropdown.map((item) => item.name),
        
                // muiEditTextFieldProps: {
                //     select: true,
                //     multiple: true,
                // },
                // muiTableBodyCellEditTextFieldProps: ({cell})=>({
                //     // defaultValue: cell.row.original.vm.join(", "),
                //     value: vm,
                //     onChange: handleChange,
                //     select: true,
                //     multiple: true,
                //     // children: () => vms_for_dropdown && vms_for_dropdown.map((vm) => (
                //     // <MenuItem key={vm.guid} value={vm.guid}>
                //     //     {vm.name}
                //     // </MenuItem>
                //     // )),
                //     children: vms_for_dropdown && vms_for_dropdown.map((vm)=>{
                //         return <MenuItem value={vm.name}>{vm.name}</MenuItem>
                //     })
                // }),
          
            },
            {
                accessorKey: 'created_at',
                header: 'Created At',
                size: 40,
                enableColumnOrdering: false,
                enableEditing: false, 
                enableSorting: false,
            },
            {
                accessorKey: 'updated_at',
                header: 'Updated At',
                size: 40,
                enableColumnOrdering: false,
                enableEditing: false, 
                enableSorting: false,
            },

    ],
    [getCommonEditTextFieldProps, vms_for_dropdown, validationErrors],
    );

    return(
        <Box>
            <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item sx={{ mx:"auto" }} xs={12} md={6} >
                    <Paper sx={{p:1}}>
                        {!loading && <div style={{ height: 400, }}>
                            <DataGrid
                                rows={vm_details}
                                columns={columns}
                                // initialState={{
                                // pagination: {
                                //     paginationModel: { page: 0, pageSize: 5 },
                                // },
                                // }}
                                pageSizeOptions={[5, 10]}
                                // checkboxSelection
                                onRowSelectionModelChange={(newRow)=>onRowsSelectionHandler(newRow)}
                            />
                        </div>}
                        <Box sx={{marginTop:2}}>
                            <MaterialReactTable
                                enableGlobalFilter={false}
                                enableDensityToggle={false}
                                enableFullScreenToggle={false}
                                enableFilters={false}
                                enableColumnFilters={false}

                                displayColumnDefOptions={{
                                'mrt-row-actions': {
                                    muiTableHeadCellProps: {
                                    align: 'center',
                                    },
                                    size: 120,
                                },
                                }}
                                columns={operator_columns}
                                data={opData}
                                editingMode="modal" //default
                                enableColumnOrdering
                                enableEditing
                                onEditingRowSave={handleSaveRowEdits}
                                onEditingRowCancel={handleCancelRowEdits}
                                renderRowActions={({ row, table }) => (
                                <Box sx={{ display: 'flex', gap: '1rem' }}>
                                    <Tooltip arrow placement="left" title="Edit">
                                    <IconButton onClick={() => table.setEditingRow(row)}>
                                        <Edit />
                                    </IconButton>
                                    </Tooltip>
                                    <Tooltip arrow placement="right" title="Delete">
                                    <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                        <Delete />
                                    </IconButton>
                                    </Tooltip>
                                </Box>
                                )}
                                renderTopToolbarCustomActions={() => (
                                <Button
                                    color="secondary"
                                    onClick={() => setCreateModalOpen(true)}
                                    variant="contained"
                                >
                                    Create New Operator
                                </Button>
                                )}
                            />
                            <CreateNewAccountModal
                                columns={operator_columns}
                                open={createModalOpen}
                                onClose={() => setCreateModalOpen(false)}
                                onSubmit={handleCreateNewRow}
                                vm_details={vms_for_dropdown}
                                vm = {vm}
                                onHandleChange = {handleChange}
                                user_id = {user_id}
                            />
                        </Box>
                    </Paper>
                </Grid>
                <Grid item sx={{  mx:"auto" }} xs={12} md={6}>
                    <Paper sx={{p:1}} variant="elevation">
                        <Typography variant="h4" gutterBottom>
                            Operator Info
                        </Typography>
                        <Divider sx={{borderBottomWidth:2, bgcolor:"gray"}} />
                        <Typography variant="h5" sx={{mt:2}}>
                            Machine Status
                        </Typography>
                        <Grid container sx={{mt:2}}>
                            <Grid item sx={{ mx:"auto" }} xs={12} md={6} >
                                <TableContainer component={Paper}>
                                    <Table sx={{}} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Id</TableCell>
                                                <TableCell >Status</TableCell>
                                                <TableCell >Sales</TableCell>
                                                <TableCell >Units Sold</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {rows && rows.map((row) => (
                                            <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                            <TableCell component="th" scope="row">
                                                {row.id}
                                            </TableCell>
                                            <TableCell >{row.status}</TableCell>
                                            <TableCell >{row.sales}</TableCell>
                                            <TableCell >{row.units_sold}</TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12} md={6} >
                                <Stack sx={{mt:1, mx: 10}} direction="row" spacing={1}>
                                    <Chip label="Total Sales: 4345656" color="primary" />
                                </Stack>
                                <Stack sx={{mt:1, mx: 10}} direction="row" spacing={1}>
                                    <Chip label="Units Sold: 9875864" color="primary" />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Stack direction="row" sx={{my:2, mx:1 }}>
                        <Divider orientation="vertical" flexItem  sx={{ borderRightWidth: 3, bgcolor: "gray",mx:1 }}  />
                            <Grid item xs={6}>
                                <Chip label="Daily Sales: 2344" color="secondary" />
                            </Grid>
                            <Divider orientation="vertical" flexItem  sx={{ borderRightWidth: 3, bgcolor: "gray",mx:1 }}  />
                            <Grid item xs={6}>
                                <Chip label="Weekly Sales: 4345" color="secondary" />
                            </Grid>
                            <Divider orientation="vertical" flexItem  sx={{ borderRightWidth: 3, bgcolor: "gray",mx:1 }}  />
                            <Grid item xs={6}>
                                <Chip label="Monthly Sales: 3545" color="secondary" />
                            </Grid>
                            <Divider orientation="vertical" flexItem  sx={{ borderRightWidth: 3, bgcolor: "gray",mx:1 }}  />
                            <Grid item xs={6}>
                                <Chip label="Yearly Sales: 8767" color="secondary" />
                            </Grid>
                        </Stack>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit, vm_details, vm, onHandleChange, user_id }) => {
    const [values, setValues] = useState(() =>
        columns.reduce((acc, column) => {
        acc[column.accessorKey ?? ''] = '';
        return acc;
        }, {}),
    );

    const handleSubmit = () => {
      //put your validation logic here
        onSubmit(values);
        onClose();
    };

    return (
        <Dialog open={open}>
        <DialogTitle textAlign="center">Create New Account</DialogTitle>
        <DialogContent>
            <form onSubmit={(e) => e.preventDefault()}>
            <Stack
                sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
                }}
            >
                {columns.map((column) => (
                (column.accessorKey !== 'created_at' && column.accessorKey !== 'updated_at' && column.accessorKey !== "guid" && column.accessorKey !== "vm") && <TextField
                    key={column.accessorKey}
                    label={column.header}
                    name={column.accessorKey}
                    onChange={(e) =>
                    setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                ))}
                <TextField
                    key="password"
                    label="Password"
                    name="password"
                    type="password"
                    onChange={(e) =>
                        setValues({ ...values, [e.target.name]: e.target.value })
                    }
                />
                <InputLabel id="demo-simple-select-label">Vending Machine</InputLabel>
                <Select 
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={vm}
                    label="Vending  Machine"
                    name="vm"
                    onChange={onHandleChange}
                    multiple={true}
                >
                    {vm_details && vm_details.map((vm)=>{
                        return <MenuItem value={vm.guid}>{vm.name}</MenuItem>
                    })}
                </Select>
            </Stack>
            </form>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="secondary" onClick={handleSubmit} variant="contained">
            Create New Operator
            </Button>
        </DialogActions>
        </Dialog>
    );
};

const validateRequired = (value) => !!value.length;
const validateEmail = (email) =>
    !!email.length &&
    email
    .toLowerCase()
    .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );