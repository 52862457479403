import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { 
    Button, 
    InputAdornment,
} from '@mui/material/';
import DeleteIcon from '@mui/icons-material/Delete';
import { Input } from '@mui/base';
import { TextField } from '@mui/material/';
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from '@mui/material/Autocomplete';
import { useCallback, useMemo, useState, useEffect } from 'react';
import "./RightGrid.css"
import {getAllVM, getAllItems, publish_slot, getAllSlotsItems} from '../../api/routes'
import RightGridTable from './RightGridTable';
import  mqtt from "mqtt";

const options = {
    username: 'pi', // Optional: if your broker requires authentication
    password: 'raspberry', // Optional: if your broker requires authentication
    rejectUnauthorized: false  // Only set to false if using a self-signed certificate
};

// Connect to the broker using wss://
const client_right_grid = mqtt.connect('wss://ivm9.in:9001');
client_right_grid.on('connect', () => {
    console.log('Connected to MQTT broker from right grid');
});

export function RightGrid({user_id}) {
    
    
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(true);
    const [itemData, setItemData] = useState(() => []);
    const [searchValue, setSearchValue] = useState(itemData[0]);
    const [rows, setRows] = useState([]);
    const [itemSlots, setItemSlots] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedVM, setSelectedVM] = useState("");
    // const [slots, setSlots] = useState(0);
    const [opVm, setOpVM] = useState([]);
    const TAX_RATE = 0.07;

    function ccyFormat(num) {
        return `${num.toFixed(2)}`;
    }

    function priceRow(qty, unit) {
        return qty * unit;
    }

    function createRow(desc, stock, qty, unit) {
        const price = priceRow(qty, unit);
        return { desc, stock, qty, unit, price };
    }

    function subtotal(items) {
        return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
    }

    function adItemToTable(event, newValue) {
        console.log("ONCHANGE>>>>>>>>>>>>>>>>>>", newValue)
        if (!newValue) return
        // console.log({newValue, event})
        setSearchValue(newValue);
        const obj = itemData.find(item => item.item_name === newValue);
        let stock = 0;
        itemSlots.forEach(item_slot => {
            if (item_slot.item_id_id === obj.guid) stock = item_slot.quantity;
        })
        // console.log({ obj });
        setRows([...rows,createRow(newValue, stock, 1, obj.item_price)]);
        selectedItems.push(obj.guid);


        // const rows = [
        //     createRow('Paperclips (Box)', 100, 1.15),
        //     createRow('Paper (Case)', 10, 45.99),
        //     createRow('Waste Basket', 2, 17.99),
        //     ];
    }

    const deleteRow = (event, index)=> {
        console.log("rrrrrrroooooooowwwwwwwwwwwws",{rows}, index);
        let curr_rows = [...rows];
        console.log("111111111", {curr_rows})
        curr_rows = curr_rows.filter((item, i) => i !== index);
        console.log("222222222", {curr_rows})

        setRows(curr_rows);
        // console.log("rrrrrrroooooooowwwwwwwwwwwws",{rows});
    }

    const setQuantity = (e, index) => {
        // console.log("E", e.target.value );
        // console.log({rows});
        const new_rows = [...rows];
        if(e.target.value){
            if (parseInt(e.target.value) <= new_rows[index]["stock"]) {
                new_rows[index]["qty"] = parseInt(e.target.value);
                new_rows[index]["price"] = parseFloat(e.target.value) * new_rows[index]["unit"];
            }
        }
        else
            new_rows[index]["qty"] = 0;
        setRows(new_rows);
    }

    function getItemSlotsForOperator(item_slots, vms, items) {
        // console.log("++++", { item_slots, vms, items });
        const resItemSlots = [];
        const resItems = [];
        item_slots.forEach(item => {
            vms.forEach(vm => {
                if (item.vending_machine_id_id === vm.guid) {
                    client_right_grid.subscribe(`vm/${vm.guid}/call/status`);
                    client_right_grid.subscribe(`heartbeat/vendingmachine/alive/${vm.guid}`)
                    client_right_grid.subscribe(`heartbeat/vendingmachine/dead/${vm.guid}`)
                    if (vm.call_status === 5)
                        resItemSlots.push(item);
                }  
            });
        })

        items.forEach(item => {
            resItemSlots.forEach(item_slot => {
                if (item_slot.item_id_id === item.guid && item_slot.quantity > 0)
                resItems.push(item);
            });
        })
        // console.log(">>>>>>>", {resItemSlots, resItems});
        return [resItemSlots, resItems];
    }

    const fetchData = async () => {
        setLoading(true);
        try {
            // const users = await getAllUsers();
            // setAdmins(getAdminsFromUsers(users));
            const [items, vms, item_slot] = await Promise.all([getAllItems(), getAllVM(), getAllSlotsItems()]);
            // console.log({vms, item_slot});
            const opVMs = vms.filter(vm => vm.operator_assigned_id === user_id)
            setOpVM(opVMs);
            const [opItemSlots, opItems] = getItemSlotsForOperator(item_slot, opVMs, items)
            setItemSlots(opItemSlots);
            setItemData(opItems);
            // setTableData(updateVMResponseData(vms));
            setLoading(false);
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    }
    
    useEffect(() => {
        console.log("useeffect");
        fetchData();
        // console.log("+++++++++++++++",{itemData});
    }, [rows]);

    useEffect(() => {
        
        client_right_grid.on('message', (topic, message) => {
            console.log('--------- MQTT message received from right grid', topic, message, typeof message);
            
            message = JSON.parse(message.toString())
            // update call status .....
            if (message["type"] === "call_status" || message["type"] === "status") {
                fetchData();
            }
        });
    },[])

    const invoiceSubtotal = subtotal(rows);
    const invoiceTaxes = TAX_RATE * invoiceSubtotal;
    const invoiceTotal = invoiceTaxes + invoiceSubtotal;
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const dispenseItem = async (event) => {
        if (
            // eslint-disable-next-line no-restricted-globals
            !confirm(`Check the payment status. Click on OK to proceed`)
            ) return;
        // setSearchValue(undefined);
        // console.log(">>>>>>>>>>>>>", {rows, selectedItems, itemSlots});
        // const item_id = selectedItems[selectedItems.length -1];
        // console.log({ item_id });
        let slot_details = [];
        // TODO: Check in selected vending machine --------------
        let on_call_vm = '';
        selectedItems.length > 0 && selectedItems.forEach((item_id, index) => {
            itemSlots.forEach((item) => {
                if (item.item_id_id === item_id) {
                    // console.log("++++++MATCHED+++++++",item)
                    on_call_vm = item.vending_machine_id_id;
                    if (rows[index]) {
                        slot_details.push({
                            slot:item.slot_id,
                            quantity: rows[index]["qty"],
                        });
                    }
                }
            });
        })
        
        // console.log({ slot_details });
        await publish_slot({'vm_data': on_call_vm, 'data': slot_details});
        setRows([]);
        setSelectedItems([]);
        setSearchValue("");
        fetchData();
    }

    return (
        <Box p={2}>
            <Grid container p={2}>
                <Grid xs={12} >
                    {/* <TextField 
                        id="standard-search"
                        label="Search . . ."
                        type="search"
                        variant="standard"  
                        sx={{ width: "100%", height:"10px" }} 
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        value={searchTerm}
                        onChange={handleChange}
                    /> */}
                    <Autocomplete
                        value={searchValue}
                        onChange={(event, newValue) => adItemToTable(event, newValue)}
                        id="free-solo-2-demo"
                        // disableClearable
                        options={itemData.map((option) => option.item_name)}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search input"
                            InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            endAdornment: (
                                <InputAdornment position="end">
                                <SearchIcon />
                                </InputAdornment>
                            ),
                            }}
                        />
                        )}
                    />
                </Grid>
                {/* <Grid xs={12} md={3}><Button variant='outlined' sx = {{mt:1, ml:2}} color='inherit' size='medium'>Search</Button></Grid> */}
            </Grid>
            <Grid container justifyContent="center" mt={3} p={2}>
               {!loading && <RightGridTable rows={rows} setQuantity={setQuantity} deleteRow={deleteRow} TAX_RATE={TAX_RATE} invoiceSubtotal={invoiceSubtotal} invoiceTaxes={invoiceTaxes} invoiceTotal={invoiceTotal} selectedItems={selectedItems} itemSlots={itemSlots} />}
            </Grid>
            <Grid p={2}>
                <Button variant='contained' size="large" onClick={dispenseItem}>Dispense</Button>
            </Grid>
        </Box>
    )
}
