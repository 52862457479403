import axios from 'axios';
// export const login =  async (data) => {
//     // 
//     await fetch("http://127.0.0.1:8000/");
// }

export const getAllVM = async () => {
    return await axios.get("/vm/")
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const getAllUsers = async () => {
    return await axios.get("/user/")
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const getAllItems = async () => {
    return await axios.get("/item/")
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const getAllSlotsItems = async () => {
    return await axios.get("/item/slots/")
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const updateVM = async (id, data) => {
    console.log("updateVM----", data);
    return await axios.put(`/vm/update/${id}/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const updateUser = async (id, data) => {
    return await axios.put(`/user/update/${id}/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const updateItem = async (id, data) => {
    console.log("updateItem >>>>", data);
    return await axios.put(`/item/update/${id}/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const createVM = async (data) => {
    console.log("createVM----------",data);
    return await axios.post(`/vm/create/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}


export const createUser = async (data) => {
    console.log("createUser----------",data);
    return await axios.post(`/user/create/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const createItem = async (data) => {
    console.log("createItem----------",data);
    return await axios.post(`/item/create/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const createSlotsItem = async (data) => {
    console.log("createSlotsItem----------",data);
    return await axios.post(`/slots_items/create/`, data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const deleteVM = async (id) => {
    return await axios.delete(`/vm/delete/${id}/`)
    .then(res => {
        console.log(res.data);
        return res.data;
    })
    .catch(err => console.log(err.message));
}

export const deleteUser = async (id) => {
    return await axios.delete(`/user/delete/${id}/`)
    .then(res => {
        console.log(res.data);
        return res.data;
    })
    .catch(err => console.log(err.message));
}

export const publish_slot = async (slot_data) => {
    return await axios.post(`/publish/slot/details/`, slot_data)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const publish_vm_status_req = async (id) => {
    return await axios.post(`/publish/vm/details/req/${id}/`)
    .then(res => {
        console.log(res.data.data);
        return res.data.data;
    })
    .catch(err => console.log(err.message));
}

export const deleteItem = async (id) => {
    return await axios.delete(`/item/delete/${id}/`)
    .then(res => {
        console.log(res.data);
        return res.data;
    })
    .catch(err => console.log(err.message));
}