import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
// import { login } from '../api/routes/routes';
import axios from 'axios';
import {
  useNavigate ,
} from "react-router-dom";

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {

    const navigate = useNavigate();

    const [user_type, setUserType] = useState('');
    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const handleChange = (event) => {
      console.log({event});
        setUserType(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if ((username === "") & (password === "")) {
          return;
        } 
        // make api call to our backend. 
        await axios.post("/login/", 
          {
            id: data.get('id'),
            password: data.get('password'),
            user_type: data.get('user_type'), 
          }
        ).then(res => {
          console.log({res});
          if (!res.data.authenticated) {
              navigate("/");
              return ;
          }
          localStorage.setItem("authenticated", true);
          console.log("Login+++",res.data.user);
          navigate("/home", {
            state:{
              role: {
                role: data.get('user_type'), 
                user_id: res.data.user.guid,
              }
            }
          });
          return;
        }).catch(err => console.log(err.message));
    };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="user_id"
              label="User ID"
              name="id"
              autoComplete="id"
              autoFocus
              onChange={(e) => setUserName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputLabel id="demo-simple-select-label">User Type</InputLabel>
            <Select 
                fullWidth
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={user_type}
                label="User Type"
                name="user_type"
                onChange={handleChange}
            >
                <MenuItem value={10}>Operator</MenuItem>
                <MenuItem value={20}>Admin</MenuItem>
                <MenuItem value={30}>Super Admin</MenuItem>
            </Select>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  );
}