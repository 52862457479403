import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Admin from './admin/Admin';
import Operator from './operator/Operator';
import SuperUser from './superuser/SuperUser';

export default function Home({role}) {
    // console.log("Home+++++++", {role});
    // console.log("Home+++++++", role.role, typeof(role.role));
    const navigate = useNavigate();
    const [authenticated, setAuthenticated] = useState(false);
    useEffect(() => {
        const loggedInUser = localStorage.getItem("authenticated");
        if (!loggedInUser) {
            navigate("/");
        }
        setAuthenticated(true);
    },[navigate, authenticated]);

    return(
        <>
        {role.role==='10'? <Operator user_id={role.user_id} /> : (role.role==='20'? <Admin user_id={role.user_id} />:<SuperUser user_id={role.user_id} /> )}
        </>
    );

}