import { LeftGrid } from "./LeftGrid";
import { RightGrid } from "./RightGrid";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Grid from '@mui/material/Grid';
// import { Logout } from "@mui/icons-material";
import Logout from '../../utils/Logout';

export function OperatorPannel({user_id}) {
    return (
        <Box sx={{ flexGrow: 1 }} style={{ backgroundColor: "#ffffff" }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Operator Panel
                    </Typography>
                    <h3 sx={{ bgcolor: "#E9DB5D"}}><Logout color="secondary"/></h3>
                </Toolbar>
            </AppBar>
            {/* <Grid container spacing={2} className="mt-5">
                <LeftGrid />
                <RightGrid />
            </Grid> */}
            {/* <Box sx={{ flexGrow: 1, height: 1000, width: '100%' }} > */}
                <Grid container >
                    <Grid item xs={6} sx={{ height: 1000, width: '100%', }}  >
                        <LeftGrid user_id={user_id} />
                    </Grid>
                    <Grid item xs={6} sx={{ height: 1000, width: '100%', }} style={{borderLeft: '4px solid darkblue'}}>
                        <RightGrid user_id={user_id} />
                    </Grid>
                </Grid>
            {/* </Box> */}
        </Box>
    )
}